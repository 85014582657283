import React from "react";
import styled from "styled-components";
import { NewsPosts_allMarkdownRemark_nodes } from "../pages/__generated__/NewsPosts";
import { CloudinaryImage } from "../utils/CloudinaryImage";

const H2 = styled.h2`
	margin-bottom: 0;
`;

const DateSpan = styled.span`
	font-size: 12px;
`;

const Image = styled.img`
	margin-bottom: 0;
`;

const CreditP = styled.p`
	text-align: right;
	font-size: 12px;
`;


export const NewsPost: React.FC<NewsPosts_allMarkdownRemark_nodes> = (props) => {
	const image = props.frontmatter!.image ? CloudinaryImage.autoUrl(props.frontmatter!.image) : null;

	return (
		<>
			<H2>{props.frontmatter!.headline}</H2>
			<DateSpan>{props.frontmatter!.date}</DateSpan>
			{image && <Image src={image} />}
			{props.frontmatter!.credit && <CreditP>{props.frontmatter!.credit}</CreditP>}

			<div dangerouslySetInnerHTML={{ __html: props.html! }} />
		</>
	);
};
