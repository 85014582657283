import { graphql, PageProps } from "gatsby";
import React from "react";
import Layout, { MainContainerDiv, PageTitle } from "../components/Layout";
import { NewsPost } from "../components/NewsPost";
import { NewsPosts, NewsPosts_allMarkdownRemark_nodes } from "./__generated__/NewsPosts";

export default (props: PageProps<NewsPosts>) => {

	return (
		<Layout title="News" fullHeader>
			<MainContainerDiv>
				<PageTitle>News</PageTitle>

				{props.data.allMarkdownRemark.nodes.map((node: NewsPosts_allMarkdownRemark_nodes, index) => {
					return (
						<div key={index}>
							{index > 0 && <hr />}
							<NewsPost {...node!} />
						</div>
					);

				})}
			</MainContainerDiv>
		</Layout>
	);
};

export const pageQuery = graphql`
	query NewsPosts {
		allMarkdownRemark(
			filter: {
				fileAbsolutePath: {regex: "/data\\/news\/.*md/"},
				frontmatter: {published: {eq: true}}
			},
			sort: {fields: frontmatter___date, order: DESC}
		) {
			nodes {
				frontmatter {
					date(formatString: "dddd, Do MMMM YYYY")
					headline
					image
					credit
				}
				html
			}
		}
	}
`;